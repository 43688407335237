import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const MainLayout = ({ children, alert }) => {
  const router = useRouter();
  const hasAppParam =
    router.asPath.includes('?app') || router.asPath.includes('&app');
  return (
    <>
      {!hasAppParam && <Header alert={alert} />}
      <main className="main">{children}</main>
      {!hasAppParam && <Footer />}
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
  alert: PropTypes.any,
};

export default MainLayout;
